import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const booomCasinoPageData: GamePageData = {
    metaPageTitle: "BOOOM! Casino by PokerStars - iDream Interactive",
    metaKeywords: "booom casino, boom casino, pokerstars casino",
    metaDescription: "An explosive Vegas Casino slots experience! Learn more about an online social casino game we designed for PokerStars.",
    pageTitle: "BOOOM! CASINO",
    descriptionTitle: "An explosive VEGAS CASINO SLOTS experience!",
    description: <>
        <p>BOOOM! Casino by PokerStars is a FREE NEW SLOTS GAME that offers you the chance to play Vegas casino slot machines. Find your favourite from your last visit to Vegas Casinos and much more! Get your Slots Rush from Vegas Casino machines!</p>
        <p>Enter BOOOM! Casino by PokerStars and collect your FREE WELCOME BONUS of 250,000 chips! Need some more chips? Come back and collect every day some more free bonus chips!</p>
        <p>Discover the exciting thrills of Social Casino Games! Play with others at the same time as if you were side by side on the Vegas Casino Floor. Add friends to your game and chat with them as you’re playing together!</p>
    </>,
    links: [],
}
